import React, { useState } from 'react'
import PropTypes from 'prop-types'
import { Link, graphql } from 'gatsby'
import Img from 'gatsby-image'
import { HelmetDatoCms } from 'gatsby-source-datocms'

import Layout from '../components/layout'

const ResourcesPage = ({ data: { page, resources } }) => {
  return (
    <Layout>
      <HelmetDatoCms seo={page.seoMetaTags} />
      <section
        className="hero is-large is-page-hero"
        style={{
          backgroundImage: `url(${page.heroBackgroundImage.fixed.src})`
        }}
      >
        <div className="hero-body">
          <div className="container">
            <h1 className="title has-text-white has-text-weight-normal">
              {page.heroTitle}
            </h1>
          </div>
        </div>
      </section>
      <ResourcesList resources={resources.nodes} />
    </Layout>
  )
}

export default ResourcesPage

const ResourcesList = ({ resources }) => {
  const [currentIndex, setIndex] = useState(6)

  const showMore = () => setIndex(index => (index += 6))

  return (
    <section className="section has-text-centered">
      <div className="columns is-multiline">
        {resources.slice(0, currentIndex).map(resource => (
          <Link
            to={`/resources/${resource.slug}`}
            key={resource.id}
            className="column is-4"
            style={{ marginBottom: '2rem' }}
          >
            <figure
              className="image is-4by3"
              style={{ marginBottom: '1rem' }}
            >
              <Img style={{ maxWidth: '100%', maxHeight: '100%' }} fixed={resource.featuredImage.fixed} alt={resource.title} />
            </figure>
            <h3 className="subtitle is-size-6 has-text-weight-bold">
              {resource.title}
            </h3>
            {/* <p className="is-size-7"><small>- {resource.__typename.replace('DatoCms', '')} -</small></p> */}
          </Link>
        ))}
      </div>
      <div
        className="container"
        style={{ display: 'flex', justifyContent: 'center' }}
      >
        <button
          className="button is-black is-outlined is-centered"
          onClick={showMore}
          style={{ display: `${currentIndex >= resources.length ? 'none' : ''}` }}
        >
          LOAD MORE ARTICLES
        </button>
      </div>
    </section>
  )
}

ResourcesList.propTypes = {
  resources: PropTypes.arrayOf(PropTypes.object).isRequired
}

ResourcesPage.propTypes = {
  data: PropTypes.object.isRequired
}

export const query = graphql`
  query OurOceanQuery {
    page: datoCmsOurOceanPage {
      seoMetaTags {
        ...GatsbyDatoCmsSeoMetaTags
      }
      heroTitle
      heroBackgroundImage {
        fixed(
          width: 1920
          imgixParams: {
            auto: "compress"
            fm: "pjpg"
            crop: "entropy"
            bri: -15
          }
        ) {
          src
        }
      }
    }
    resources: allDatoCmsResource(sort: {fields: publishedDate, order: DESC}) {
      nodes {
        id
        __typename
        ... on DatoCmsDownload {
          title
          slug
          featuredImage {
            fixed(width: 400, height: 300, imgixParams: {fm: "pjpg", auto: "compress", crop: "entropy", fit: "crop"}) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
        ... on DatoCmsPost {
          title
          slug
          featuredImage {
            fixed(width: 400, height: 300, imgixParams: {fm: "pjpg", auto: "compress", crop: "entropy", fit: "crop"}) {
              ...GatsbyDatoCmsFixed
            }
          }
        }
      }
    }
  }
`
